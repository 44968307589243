import { PairingRoundListInOrgDetails } from '../components/Pairing/Round';

export function OrganizationPairingRoundsListRoute() {
  return <PairingRoundListInOrgDetails />;
}

export const Component = OrganizationPairingRoundsListRoute;

export function clientLoader() {
  return null;
}
